import React from "react"
import { GlobalStyles, CssBaseline } from "@mui/material"

export const wrapRootElement = ({ element }) => (
  <>
    <CssBaseline />
    <GlobalStyles
      styles={{
        html: {
          scrollBehavior: "smooth",
        },

        a: { color: "inherit", textDecoration: "inherit" },
        ul: { margin: 0, padding: 0 },
      }}
    />
    {element}
  </>
)
